
import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../../../src/environments/environment';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-live-webiners',
  templateUrl: './live-webiners.component.html',
  styleUrls: ['./live-webiners.component.scss']
})
export class LiveWebinersComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 9;
  LiveWebinarDatas:any;
  LiveWebinarLoaded:boolean;
  firstString:string;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoaded: boolean;
  MetaTagData: any;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,private routeUrl: Router) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.AllLiveWebinars();
    this.AllMetaTagLiveWebinar();
  }

  AllLiveWebinars(): void {
   // if(this.LiveWebinarLoaded!=true){
      this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.LiveWebinarLoaded = false;

    //this.count=this.LiveWebinarDatas[0].TotalDataCount;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllLiveWebinars?siteName='+this.envSiteName+'&pageNumber='+this.page)
      .subscribe(
        items => {
          const platform = isPlatformBrowser(this.platformId) ?
          'in the browser' : 'on the server';
          console.log(`getUsers : Running ${platform} with appId=${this.appId}`);
          this.LiveWebinarDatas = items;
          this.count=this.LiveWebinarDatas[0].TotalDataCount;
          // this.count=0;     
          this.LiveWebinarLoaded = true;
          console.log("Live AllWebinars",this.LiveWebinarDatas)
          this.state.set(STATE_KEY_ITEMS, <any> items);
          window.scrollTo(0,0);
        });
    //  }
  }
  resetUsers(): void {
    this.LiveWebinarDatas = null;
    this.LiveWebinarLoaded = true;
  }
  
  redirect(getUrl:any,getId:any)
  {
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https")
    {
      window.open(getUrl, "_blank");
    }else
    {
      this.routeUrl.navigate(['/events/live-webinars/'+getUrl+"/"+getId]);
    }
  }

// on click of pagination tabs

  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.AllLiveWebinars();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}

AllMetaTagLiveWebinar(): void {
  if(this.MetaTagLoaded!=true){
  this.MetaTagLoaded = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'live Webinars')
  .subscribe(
    items => {
      this.MetaTagData = items[0];
      this.MetaTagLoaded=true;
      console.log('this.MetaTagData',this.MetaTagData);


        //seo code for tags
        this.titleService.setTitle(this.MetaTagData.PageTitle);

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagData.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagData.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagData.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagData.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagData.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagData.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagData.OgImageURL}
        );
        //seo code for tags end
      });
    }
}




}
