<!-- Modal -->
<div class="modal fade news_main_modal" id="memberLoginModalCenter" tabindex="-1" role="dialog" aria-labelledby="memberLoginModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered news_modal_center" role="document">
        <div class="modal-content member_modal_content">
            <button type="button" class="close pop-close" data-dismiss="modal" aria-label="Close"  (click)="onReset()">
                <span aria-hidden="true">&times;</span>
              </button>
            <div class="modal-body member-modal-body">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                <p class="pop-title text-capitalize text-center pop-title">Welcome back!</p>
                <p class="text-center pop-para">Put your news, events, company, and promotional content in front of thousands of your peers and potential customers.</p>
                 <p class="text-danger">{{Message}}</p>
                <div class="form-group">
                    <input formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"  type="email" class="form-control" id="txtEmailLogin" aria-describedby="emailHelp" placeholder="*Email">
                     
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">*Required</div>
                        <div *ngIf="f.email.errors.email">*Invalid email</div>
                    </div>    
                    
                </div>
                <div class="form-group">
                    <input formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" type="password" class="form-control" id="txtPasswordLogin" aria-describedby="emailHelp" placeholder="*Password">
                     
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">*Required</div>
                        <div *ngIf="f.password.errors.minlength">*Password must be at least 4 characters
                        </div>
                    </div>
                </div>
                <!-- <div class="form-check text-center mb-3">
                    <input type="checkbox" formControlName="acceptTerms" id="accepttermsSignup"
                        class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">

                    <label class=" pop-para" for="acceptTerms"> I agree to the  
                        <a target="_blank" routerlink="/terms-and-service" href="" data-dismiss="modal" aria-label="Close">terms of
                            service</a> and <a target="_blank" routerlink="/privacy-policy" href="" data-dismiss="modal" aria-label="Close">privacy policy</a>.
                        </label>
                    <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">Required</div>
                </div> -->
                <div class="form-check text-center mb-3">
                    <input type="checkbox" class="form-check-input" id="accepttermsSignup" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">
                    <label class=" pop-para" for="acceptTerms"> I agree to the <a href="" data-dismiss="modal" aria-label="Close" routerLink='/terms-and-service'>terms of service</a> and <a href="" data-dismiss="modal" aria-label="Close"
                        routerLink='/privacy-policy'>privacy Policy</a>.</label>

                    <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">*Required</div>

                </div>
                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3"> Submit</button>
                </form>
                <p class="para-5 text-center ">Not a member yet? Not a problem, <span class="para-2-c-2 cursor-p" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#memberSignupModalCenter">Sign Up</span></p>
            </div>
        </div>
    </div>
</div>