import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
// import { environment } from '../../../../../src/environments/environment';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-globel-resources',
  templateUrl: './globel-resources.component.html',
  styleUrls: ['./globel-resources.component.scss']
})
export class GlobelResourcesComponent implements OnInit {

  href:any;
  lastString:string;
  envSiteName:any;
  GlobalResourcesTopAD:SafeResourceUrl;
  url:string='https://'+environment.siteName+'/ADcodeFile/GlobalResourcesTopAD.html'

  constructor(private router:Router,private sanitizer:DomSanitizer) {this.envSiteName=environment.siteName; }

  ngOnInit(): void {
    this.GlobalResourcesTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
  ngOnChanges(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1)
    console.log("this.lastString",this.lastString);
  }

}
